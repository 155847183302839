@import url(https://fonts.googleapis.com/css2?family=Dosis&family=Nunito+Sans&family=Righteous&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0 !important;
  padding: 0 0 !important;
  background: linear-gradient(90deg, #eee, #f5f5f5, transparent);
  overflow-x: hidden;
  font-family: 'Nunito Sans', sans-serif !important;
  min-height: 100vh !important;
}

#root {
  min-height: 100vh !important;
  position: relative;
  overflow: hidden;
}

#root .layer {
  position: absolute;
  background-color: #eee;
  width: 100%;
  height: 135%;
  right: -49%;
  top: 0;
  box-shadow: 0px -1px 3px #7eb1df;
  transform: rotate(15deg);
}

a {
  text-decoration: none !important;
}

.show-logo-responsive {
  display: none;
}

.diff-color {
  color: #7eb1df;
}

/* Nav */

nav .icons-cont {
  margin-left: auto;
  padding: 20px 0;
  text-align: right;
}

nav .icons-cont .icons-btn {
  display: inline-block;
  background-color: transparent;
  text-align: center;
  color: #7eb1df;
  font-size: 16px;
  width: 30px;
  line-height: 30px;
  margin-right: 8px;
  border-radius: 5px;
  border: 1px solid #7eb1df;
  transition: all 1s;
}

nav .icons-cont .icons-btn:hover {
  background-color: #7eb1df;
  color: #fff;
  transition: all 1s;
  transition: color 0.5s;
}

nav .modal-header {
  color: #7eb1df;
  border-bottom: 1px solid #7eb1df;
  padding: 10px;
}

nav .modal-body {
  text-align: left;
}

nav .logo-cont img {
  width: 150px;
}

/* Section */

.counter-sec {
  padding: 30px 0;
}

.data-cont {
  padding-top: 10vh;
  text-align: center;
}

.data-cont h1 {
  position: relative;
  text-transform: uppercase;
  padding: 0 20px;
  margin: 30px auto;
  font-family: 'Dosis', sans-serif;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-weight: bold;
  font-size: 60px;
}

.data-cont h5:last-of-type {
  width: 75%;
  margin: auto;
}

.counter {
  padding-top: 20vh;
}

.counter p {
  margin: 0;
}

.counter .counter-cont {
  margin: 0px 7px;
  display: inline-block;
}

.counter .counter-cont span {
  display: inline-block;
  margin: 0px 5px 0 0;
  font-family: 'Dosis', sans-serif;
}

.counter .counter-cont .counter-number {
  font-weight: bold;
  font-size: 30px;
}

/* Responsive */

@media (max-width: 992px) {

  .data-cont h1 {
    margin: 5px auto 50px;
  }

  .data-cont {
    padding-top: 0;
  }

  .counter {
    padding-top: 25px;
  }

  .layer {
    width: 100% !important;
    height: 60% !important;
    left: 0;
    top: 0;
    box-shadow: 1px 0px 3px #7eb1df !important;
    transform: none !important;
  }

  .hide-logo-responsive {
    display: none;
  }

  .show-logo-responsive {
    display: flex;
  }
}

@media (max-width: 768px) {
  .counter .counter-big-cont .counter-cont {
    padding: 10vh 0;
  }
}

@media (max-width: 576px) {
  nav .icons-cont {
    padding: 5px 0;
  }

  nav .icons-cont a {
    line-height: 25px;
  }

  .layer {
    height: 50% !important;
  }

    .data-cont h1 {
      font-size: 2.3rem;
      margin: 5px auto 50px;
    }

  }

  @media (max-width: 400px) {
    .data-cont h1 {
      font-size: 2rem;
      margin: 5px auto 50px;
    }
  }
